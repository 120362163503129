import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Sidebar, Header, Footer, SidebarFooter } from '../Layout-components/Layout-components';

export interface INoSidebarProps {
    children?: any;
    footerFixed?: boolean;
    contentBackground?: any;
}

export const NoSidebar = (props: INoSidebarProps) => {
    const {
        children,
        footerFixed,
        contentBackground
    } = props;

    return (
        <Fragment>
            <div className={clsx('app-wrapper', contentBackground)}>
                <Header />
                <div
                    className={clsx('app-main')}>
                    <div
                        className={clsx('app-content', {
                            'app-content-footer-fixed': footerFixed
                        })}>
                        <div className="app-content--inner">
                            <div className="app-content--inner__wrapper">{children}</div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

NoSidebar.propTypes = {
    children: PropTypes.node
};

export default NoSidebar;