import React, { Fragment } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Avatar, Box, Badge, Menu, Button, List, ListItem, Tooltip, Divider, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useUserContext } from '../Context/UserContext';

const StyledBadge = withStyles({
    badge: {
        backgroundColor: 'var(--success)',
        color: 'var(--success)',
        boxShadow: '0 0 0 2px #fff',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
})(Badge);

export default function HeaderUserbox() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userContext = useUserContext();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const userFeedback = () => {
        window.open(`mailto:ecommercesupport@nucor.com?subject=Online Credit Application Feedback`);
    };

    return (
        userContext.user && userContext.user.id && userContext.user.isAuthenticated
            ?
            <Fragment>
                <Button
                    color="inherit"
                    onClick={handleClick}
                    className="text-left btn-inverse d-flex align-items-center">
                    <Box>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            variant="dot">
                            <Avatar
                                key={userContext.user.id}
                                style={{
                                    fontSize: '12px',
                                    border: '2px solid #fafafa',
                                    marginLeft: '-8px',
                                    backgroundColor: '#006325',
                                    height: 44,
                                    width: 44
                                }} >
                                {userContext.user.initials}
                            </Avatar>
                        </StyledBadge>
                    </Box>
                    <div className="d-none d-sm-block pl-3">
                        <div className="font-weight-bold pt-2 line-height-1">
                            {userContext.user.name}
                        </div>
                        <span className="text-white-50">
                            {userContext.user.emailAddress}
                        </span>
                    </div>
                    <span className="pl-2 pl-xl-2">
                        <FaAngleDown className="opacity-5" />
                    </span>
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    getContentAnchorEl={null}
                    open={Boolean(anchorEl)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                    }}
                    onClose={handleClose}
                    className="ml-2">
                    <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                            <Box>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    variant="dot">
                                    <Avatar
                                        key={userContext.user.id}
                                        style={{
                                            fontSize: '12px',
                                            border: '2px solid #fafafa',
                                            marginLeft: '-8px',
                                            backgroundColor: '#006325',
                                            height: 44,
                                            width: 44
                                        }} >
                                        {userContext.user.initials}
                                    </Avatar>
                                </StyledBadge>
                            </Box>
                            <div>
                                <div className="font-weight-bold text-center pt-2 line-height-1">
                                    {userContext.user.name}
                                </div>
                                <span className="text-black-50 text-center">
                                    {userContext.user.emailAddress}
                                </span>
                            </div>
                            <Divider className="w-100 mt-2" />
                            <ListItem button onClick={userFeedback}>
                                Feedback
                            </ListItem>
                            <ListItem button>
                                <RouterLink
                                    style={{ textDecoration: 'none' }}
                                    to="/Login">
                                    <span>Logout</span>
                                </RouterLink>
                            </ListItem>
                        </List>
                    </div>
                </Menu>
            </Fragment>
            :
            <Fragment />
    );
}
