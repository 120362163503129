import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Hidden,
    Drawer,
    IconButton,
    AppBar,
    Paper,
    Box,
    Button,
    Tooltip
} from '@material-ui/core';

import SidebarHeader from './SidebarHeader';
import SidebarUserbox from './SidebarUserbox';
import SidebarMenu from './SidebarMenu';

import navItems from './SidebarNavItems';

import { NavLink as RouterLink } from 'react-router-dom';

import {
    Settings,
    CloudDrizzle,
    Search,
    Users,
    LifeBuoy,
    Coffee,
    Briefcase,
    Bell
} from 'react-feather';
import { useUserContext } from '../Context/UserContext';

export interface ISidebarCollapsedProps {
    sidebarToggleMobile?: boolean;
    toggleSidebarMobile?: any;
}

const SidebarCollapsed = (props: ISidebarCollapsedProps) => {
    const {
        sidebarToggleMobile,
        toggleSidebarMobile
    } = props;

    const userContext = useUserContext();

    //const closeDrawer = () => { toggleSidebarMobile };

    const sidebarMenuContent = (
        <div>
            {navItems.map(list => (
                <SidebarMenu
                    component="div"
                    key={list.label}
                    pages={list.content}
                    title={list.label}
                />
            ))}
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                {/*<Drawer*/}
                {/*    anchor="left"*/}
                {/*    open={sidebarToggleMobile}*/}
                {/*    onClose={closeDrawer}*/}
                {/*    variant="temporary"*/}
                {/*    elevation={4}*/}
                {/*    className="app-sidebar-wrapper-lg">*/}
                {/*    <SidebarHeader sidebarToggleMobile={sidebarToggleMobile} toggleSidebarMobile={toggleSidebarMobile} />*/}
                {/*    <PerfectScrollbar>*/}
                {/*        <SidebarUserbox />*/}
                {/*        {sidebarMenuContent}*/}
                {/*    </PerfectScrollbar>*/}
                {/*</Drawer>*/}
            </Hidden>
            <Hidden mdDown>
                <Paper elevation={7} square className="app-sidebar-collapsed-wrapper">
                    <AppBar color="secondary" position="relative" elevation={0}>
                        <div className="sidebar-collapsed-logo">
                            <Box
                                className="header-logo-wrapper"
                                title="Berkshire Partners">
                                <Link
                                    to="/"
                                    className="header-logo-wrapper-link">
                                    <IconButton
                                        color="primary"
                                        size="medium"
                                        className="header-logo-wrapper-btn">
                                    </IconButton>
                                </Link>
                            </Box>
                        </div>
                    </AppBar>
                </Paper>
            </Hidden>
        </Fragment>
    );
};

export default SidebarCollapsed;