import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';

import HeaderLogo from './HeaderLogo';
import HeaderNotifications from './HeaderNotifications';
import HeaderDrawer from './HeaderDrawer';
import HeaderUserbox from './HeaderUserbox';
import HeaderMenu from './HeaderMenu';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

export interface IHeaderProps {
    headerShadow?: boolean;
    headerFixed?: boolean;
    sidebarToggle?: boolean;
    toggleSidebar?: any;
    sidebarToggleMobile?: boolean;
    toggleSidebarMobile?: any;
    isCollapsedLayout?: boolean;
}

const Header = (props: IHeaderProps) => {
    const {
        headerShadow,
        headerFixed,
        sidebarToggle,
        toggleSidebar,
        sidebarToggleMobile,
        toggleSidebarMobile,
        isCollapsedLayout
    } = props;

    return (
        <Fragment>
            <AppBar
                color="secondary"
                className={clsx('app-header', {
                    'app-header-collapsed-sidebar': isCollapsedLayout
                })}
                position={headerFixed ? 'fixed' : 'absolute'}
                elevation={headerShadow ? 11 : 3}>
                {!isCollapsedLayout && <HeaderLogo />}
                <Box className="app-header-toolbar">
                    <Box className="d-flex align-items-center">
                     {/*   <HeaderMenu />*/}
                    </Box>
                    
                    <Box className="d-flex align-items-center">
                        <HeaderUserbox />
                    </Box>
                </Box>
            </AppBar>
        </Fragment>
    );
};

export default Header;