import INetDataSource, { INetDataSourceConfiguration } from "./INetDataSource";
import NetDataSource from "./NetDataSource";
import { IUserLogin } from "../../Models/IUserLogin";
import { RedirectUri } from "../Common/Constants";
import { IUser } from "../../Models/IUser";
import { IProvisionalAccount } from "../../Models/IProvisionalAccount";

export default class UserDataSource extends NetDataSource<INetDataSourceConfiguration> implements INetDataSource {
    constructor(user: IUser) {
        super(user, { baseUrl: RedirectUri, scopes: [] });
    }

    async checkUserSessionIsValid(): Promise<IUser> {
        let user: IUser = {};
        user.isAuthenticated = false;

        var userObject = localStorage.getItem('nucorOCAUser');

        if (userObject) {
            user = JSON.parse(userObject);
            let response = await this.execute('POST', 'api/Account/CheckUserSessionIsValid', user) as any;

            user.isAuthenticated = response.isAuthenticated;

            if (user.isAuthenticated) {
                // Update Sliding Expiration
                user.slidingExpiration = response.slidingExpiration;
                localStorage.setItem('nucorOCAUser', JSON.stringify(user));
            }
        }

        return user;
    }

    async login(data: IUserLogin): Promise<IUser | null> {
        let user: IUser | null = null;
        let response = await this.execute('POST', 'api/Account/Login', data) as any;

        if (response) {
            user = {
                id: response.id,
                name: response.firstName + " " + response.lastName,
                initials: (response.firstName ? response.firstName.charAt(0) : "") + (response.lastName ? response.lastName.charAt(0) : ""),
                emailAddress: response.emailAddress,
                contactId: response.contactId,
                enterpriseCreditId: response.enterpriseCreditId,
                companyId: response.companyId,
                creditApplicationId: response.creditApplicationId,
                token: response.token,
                slidingExpiration: response.slidingExpiration,
                absoluteExpiration: response.absoluteExpiration,
                isAuthenticated: response.isAuthenticated,
                loginErrorMessage: response.loginErrorMessage
            }
        }

        return user;
    }

    async saveAccountUser(data: IProvisionalAccount): Promise<string> {
        var accountUserData = this.provisionalAccountMapping(data);

        var provisionalAccountId = await this.execute('POST', 'api/Account/CreateProvisionalAccount', accountUserData) as any;

        return provisionalAccountId;
    }

    async sendResetPasswordLinkEmail(emailAddress: string): Promise<boolean> {
        var resetPasswordLinkSent = await this.execute('POST', 'api/Account/SendResetPasswordLink', emailAddress) as any;

        return resetPasswordLinkSent;
    }

    async getProvisionalAccountById(id: string): Promise<IProvisionalAccount> {
        var provisionalAccountRecord;

        if (id) {
            provisionalAccountRecord = await this.execute('POST', 'api/Account/GetProvisionalAccount', id) as any;
        }

        if (provisionalAccountRecord) {
            var provisionalAccount: IProvisionalAccount = {
                id: provisionalAccountRecord.id,
                emailAddress: provisionalAccountRecord.emailAddress,
                tokenIsValid: true
            }

            return provisionalAccount;
        }

        return this.getBlankProvisionalAccount();
    }

    async resetPassword(data: IProvisionalAccount): Promise<boolean> {
        var accountUserData = {
            id: data.id,
            password: data.password
        };

        var resetPasswordSuccess = await this.execute('POST', 'api/Account/ResetPassword', accountUserData) as any;

        return resetPasswordSuccess;
    }

    provisionalAccountMapping = (data: IProvisionalAccount): any => {
        var accountUserData = {
            emailAddress: data.emailAddress,
            jobTitle: data.jobTitle,
            password: data.password,
            titleValue: data.title ? data.title.id : null,
            firstName: data.firstName,
            middleInitial: data.middleInitial,
            lastName: data.lastName,
            suffix: data.suffix,
            companyName: data.companyName,
            street1: data.street1,
            street2: data.street2,
            city: data.city,
            stateId: data.state ? data.state.id : null,
            postalCode: data.postalCode,
            countryId: data.country ? data.country.id : null,
            phoneNumber: data.phoneNumber,
            recaptchaResponse: data.recaptchaResponse
        }

        return accountUserData;
    }

    getBlankProvisionalAccount = () => {
        let blank: IProvisionalAccount = {
            emailAddress: "",
            jobTitle: "",
            password: "",
            title: null,
            firstName: "",
            middleInitial: "",
            lastName: "",
            suffix: "",
            companyName: "",
            street1: "",
            street2: "",
            city: "",
            state: null,
            postalCode: "",
            country: null,
            phoneNumber: "",
            recaptchaResponse: "",
            tokenIsValid: false
        };

        return blank;
    }
}