import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

export interface IScrollToTopProps {
    children?: any,
    location: {
        pathname: string
    }
}

const ScrollToTop = (props: IScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [props.location.pathname]);

  return props.children || null;
};

export default withRouter(ScrollToTop);
