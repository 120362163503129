import 'bootstrap/dist/css/bootstrap.css';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { IUser } from './Models/IUser';
import UserDataSource from './DataSources/Net/UserDataSource';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// User will initally be empty and will be populated after user login
let user: IUser = {};

let ds: UserDataSource = new UserDataSource(user);

ds.checkUserSessionIsValid().then((user) => {
    ReactDOM.render(
        <Fragment>
            <App user={user} />
        </Fragment>,
        rootElement);

    registerServiceWorker();
}).catch((e: Error) => {
    <Redirect to={{ pathname: `/Login`, state: { sessionExpired: true } }} />
});


