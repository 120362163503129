import React, { Component } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Chip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        fullWidth: {
            width: "100%"
        }
    }),
);

export default class WrapperAccordion extends Component<any> {
    render() {
        return (
            <Accordion defaultExpanded={true} className="mt-2 mb-4">
                <AccordionSummary expandIcon={<ExpandMoreIcon />} hidden={this.props.hidden}>
                    <h5 className="display-5 font-weight-bold mt-2">
                        {this.props.sectionHeading}
                        <Chip label={this.props.recordCount} color="primary" className="ml-3" />
                    </h5>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }} hidden={this.props.hidden}>
                    {this.props.children}
                </AccordionDetails>
            </Accordion>
        );
    }
}
