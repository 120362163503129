import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';
import FolderOpenTwoTone from '@material-ui/icons/FolderOpenTwoTone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {IconButton, Box, Typography, Badge, Button, Divider, Tooltip, withStyles, Theme, createStyles, makeStyles, Menu, MenuItem, Link} from '@material-ui/core';
import NotificationsTwoToneIcon from '@material-ui/icons/NotificationsTwoTone';
import { useUserContext } from '../Context/UserContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconLink: {
            textDecoration: 'none',
            color: '#ffffff'
        }
    }),
);

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            fontSize: '12px',
            backgroundColor: '#3DAE2B'
        },
    }),
)(Badge);

export default function HeaderNotifications() {
    const userContext = useUserContext();
    const classes = useStyles();
    const [anchorElReports, setAnchorElReports] = React.useState(null);

    const handleReportsButtonClick = (event: any) => {
        setAnchorElReports(event.currentTarget);
    };

    const handleReportsMenuClose = () => {
        setAnchorElReports(null);
    };

    return (
        userContext.user
            ?
            <Fragment>
                <RouterLink to="/IndustryTeams/Reminders">
                    <Button
                        color="inherit"
                        size="large"
                        startIcon={<StyledBadge><NotificationsTwoToneIcon /></StyledBadge>}
                        className={`text-capitalize px-3 text-left btn-inverse d-flex align-items-center ${classes.iconLink}`}>
                        <div className="d-none d-xl-block pl-1">
                            <div className="font-weight-bold">
                                Reminders
                            </div>
                        </div>
                    </Button>
                </RouterLink>

                <Button
                    color="inherit"
                    onClick={handleReportsButtonClick}
                    startIcon={<FolderOpenTwoTone />}
                    className="text-capitalize bpx-3 text-left btn-inverse">
                    <div className="d-none d-xl-block">
                        <div className="font-weight-bold">
                            Reports
                        </div>
                    </div>
                    <span className="pl-1 pl-xl-3">
                        <FaAngleDown className="opacity-5" />
                    </span>
                </Button>
            </Fragment>
            :
            <Fragment/>
    );
}
