var redirectUri: string = "https://localhost:44390";

if (window.location.hostname.indexOf("local") >= 0) {
    redirectUri = "https://localhost:44390";
}
else if (window.location.hostname.indexOf("dev") >= 0) {
    redirectUri = "https://creditappdev.nucor.com";
}
else if (window.location.hostname.indexOf("uat") >= 0) {
    redirectUri = "https://creditappuat.nucor.com";
}
else {
    redirectUri = "https://creditapp.nucor.com";
}

export const RedirectUri: string = redirectUri;