import React, { Component, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import ScrollToTop from './Utils/ScrollToTop';
import './Assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { UserContext } from './Context/UserContext';
import { IUser } from './Models/IUser';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './Components/Common/AppInsights';

export interface IAppProps {
    user: IUser
}

export default (props: IAppProps) => {
    const [user, setUser] = React.useState<any>(props.user);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <UserContext.Provider value={{ user, updateUser: setUser }}>
                <BrowserRouter basename="/">
                    <CssBaseline />
                    <ScrollToTop>
                        <Routes />
                    </ScrollToTop>
                </BrowserRouter>
            </UserContext.Provider>
        </AppInsightsContext.Provider>
    );
}
