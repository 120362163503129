import React, { Fragment } from 'react';
import { RiTeamLine } from 'react-icons/ri';
import { FaAngleDown } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { Settings, Briefcase, Users, Layers } from 'react-feather';
import { Grid, Box, Typography, Popover, Menu, Button, List, ListItem, Divider, MenuItem, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useUserContext } from '../Context/UserContext';
import bpToolsLogo from '../Assets/images/berkshire/BD_Tools.png';
import industryTeamsLogo from '../Assets/images/berkshire/Industry_Teams.png';
import bpOneIconGray from '../Assets/images/berkshire/BPONE_icon_40x40.jpg';
import oneProjectLogo from '../Assets/images/berkshire/One_Project.png';
import irOneLogo from '../Assets/images/berkshire/IR_One.png';

const HeaderMenu = () => {
    const userContext = useUserContext();

    const [anchorElTeam, setAnchorElTeam] = React.useState(null);
    const [anchorElPortal, setAnchorElPortal] = React.useState(null);

    const handleTeamButtonClick = (event: any) => {
        setAnchorElTeam(event.currentTarget);
    };

    const handlePortalButtonClick = (event: any) => {
        setAnchorElPortal(event.currentTarget);
    };

    return (
        <Fragment>
            <div className="app-header-menu">
                <Button
                    color="inherit"
                    onClick={handleTeamButtonClick}
                    startIcon={<RiTeamLine />}
                    className="text-capitalize px-3 text-left btn-inverse">
                    <div className="pl-1">
                        <div className="font-weight-bold">
                            <span className="d-none d-xl-inline pl-1">Dashboard</span>
                        </div>
                    </div>
                    <span className="pl-1 pl-xl-3">
                        <FaAngleDown className="opacity-5" />
                    </span>
                </Button>

                <Button
                    onClick={handlePortalButtonClick}
                    color="inherit"
                    size="medium"
                    className="text-capitalize bpx-3 text-left btn-inverse">
                    <div className="font-weight-bold">
                        BP ONE <span className="d-none d-xl-inline pl-1">Portals</span>
                    </div>
                    <span className="pl-1 pl-xl-3">
                        <FaAngleDown className="opacity-5" />
                    </span>
                </Button>
            </div>
        </Fragment>
    );
};

export default HeaderMenu;
