import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import { ClimbingBoxLoader, RingLoader } from 'react-spinners';
import MuiTheme from './Theme';

// Layout Blueprints
import { LeftSidebar, NoSidebar, CollapsedSidebar, MinimalLayout, PresentationLayout } from './Layout-blueprints/Layout-blueprints';

// Pages
// Online Credit Application
const Login = lazy(() => import('./Pages/Login'));
const Home = lazy(() => import('./Pages/Home'));
const CreateSplash = lazy(() => import('./Pages/CreateSplash'));
const CreditApplication = lazy(() => import('./Pages/CreditApplication'));
const CreateAccountUser = lazy(() => import('./Pages/CreateAccountUser'));
const PrintSigning = lazy(() => import('./Pages/PrintSigning'));
const PrintApplication = lazy(() => import('./Pages/PrintApplication'));
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword'));

const Routes = () => {
    const location = useLocation();

    const pageVariants = {
        initial: {
            opacity: 0,
            scale: 0.99
        },
        in: {
            opacity: 1,
            scale: 1
        },
        out: {
            opacity: 0,
            scale: 1.01
        }
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.4
    };

    const SuspenseLoading = () => {
        return (
            <Fragment>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <RingLoader color={'#5383ff'} loading={true} />
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Loading, Please wait...
                    </div>
                </div>
            </Fragment>
        );
    };
    return (
        <ThemeProvider theme={MuiTheme}>
            <AnimatePresence>
                <Suspense fallback={<SuspenseLoading />}>
                    <Switch>
                        <Redirect exact from="/" to="/Home" />

                        <Route path={[
                            '/Login',
                            '/PrintSigning',
                            '/PrintApplication'
                        ]}>
                            <MinimalLayout>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/Login" component={Login} />
                                        <Route path="/PrintSigning/:eSignatureLanguageId/:eSignatureSigningId" component={PrintSigning} />
                                        <Route path="/PrintApplication/:creditApplicationId/:tokenId" component={PrintApplication} />
                                    </motion.div>
                                </Switch>
                            </MinimalLayout>
                        </Route>

                        <Route path={[
                            '/Home',
                            '/CreateSplash',
                            '/CreditApplication',
                            '/CreateAccountUser',
                            '/ForgotPassword',
                            '/ResetPassword'
                        ]}>
                            <NoSidebar>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/Home" component={Home} />
                                        <Route path="/CreateSplash" component={CreateSplash} />
                                        <Route path="/CreditApplication" component={CreditApplication} />
                                        <Route path="/CreateAccountUser" component={CreateAccountUser} />
                                        <Route path="/ForgotPassword" component={ForgotPassword} />
                                        <Route path="/ResetPassword/:provisionalAccountId" component={ResetPassword} />
                                    </motion.div>
                                </Switch>
                            </NoSidebar>
                        </Route>
                    </Switch>
                </Suspense>
            </AnimatePresence>
        </ThemeProvider>
    );
};

export default Routes;
