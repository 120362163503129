import { IUser } from "../../Models/IUser";
import INetDataSource, { INetDataSourceConfiguration } from "./INetDataSource";

/**
 * Represents a datasource connecting to the .Net web api controllers
 */
export default class NetDataSource<T extends INetDataSourceConfiguration> implements INetDataSource {
    protected readonly config: T;
    protected readonly user: IUser;

    constructor(user: IUser, config: T) {
        this.config = config;
        this.user = user;
    }

    // helpers
    protected async handleResponse(response: Response): Promise<any> {
        if (response.ok) {
            return await response.json();
        } else {
            let text = await response.text();
            throw new Error(`${response.status} ${response.statusText} ${text}`);
        }
    }

    // public interface
    async execute(method: string, url: string, body?: any): Promise<any> {
        let response = await fetch(`${this.config.baseUrl}/${url}`, {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8",
                "X-Token": "" + this.user?.emailAddress + "|" + this.user?.token + ""
            },
            body: JSON.stringify(body)
        });
        return this.handleResponse(response);
    }
}