import React, { Component } from 'react';

import { Card, CardContent, Chip } from '@material-ui/core';

export default class WrapperSeamless extends Component<any> {
    render() {
        return (
            <div className="example-card-seamless mb-4-spacing">
                <h5 className="display-5 mb-4 font-weight-bold">
                    {this.props.sectionHeading}
                    <Chip label={this.props.recordCount} color="primary" className="ml-3" />
                </h5>
                {this.props.children}
            </div>
        );
    }
}
