import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Grid, Input, InputLabel, InputAdornment, FormControlLabel, Hidden, IconButton, Paper, Box, Typography, Dialog, Checkbox, Tabs, Tab, LinearProgress, Card, CardContent, Button, Tooltip, FormControl, Menu, MenuItem } from '@material-ui/core';

import { HiOutlineOfficeBuilding as CompanyIcon } from 'react-icons/hi';
import CommentIcon from '@material-ui/icons/Comment';
import { FiPhoneOutgoing as ExpertCallIcon } from 'react-icons/fi';
import ContactIcon from '@material-ui/icons/AssignmentInd';
import { useUserContext } from '../Context/UserContext';
import { RiTeamLine } from 'react-icons/ri';
import { FaAngleDown } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';

function PageTitle(props: any) {
    const {
        pageTitleStyle,
        pageTitleBackground,
        pageTitleShadow,
        pageTitleDescription
    } = props;

    const userContext = useUserContext();
   
    return (
        <Fragment>
            <Paper
                square
                elevation={pageTitleShadow ? 6 : 2}
                className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}>
                <div>
                    <Box className="app-page-title--first">
                        <div className="app-page-title--heading">
                            <h1>{props.titleHeading}</h1>
                            {pageTitleDescription && (
                                <div className="app-page-title--description">
                                    {props.titleDescription}
                                </div>
                            )}
                        </div>
                    </Box>
                </div>

            </Paper>
        </Fragment>
    );
};

export default PageTitle;
