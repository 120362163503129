import React, { Fragment } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';

import SidebarHeader from './SidebarHeader';
import SidebarUserbox from './SidebarUserbox';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';

import industryTeamDashboardNavItems from './SidebarNavItems';

import { useUserContext } from '../Context/UserContext';

export interface ISidebarProps {
    sidebarFixed?: boolean;
    sidebarHover?: boolean;
    hoverSidebarOn?: () => void;
    hoverSidebarOff?: () => void;
    sidebarToggle?: boolean;
    toggleSidebar?: () => void;
    sidebarToggleMobile?: boolean;
    toggleSidebarMobile?: () => void;
    sidebarUserbox?: boolean;
    sidebarShadow?: boolean;
    sidebarFooter?: boolean;
}

const Sidebar = (props: ISidebarProps) => {
    const {
        sidebarFixed,
        sidebarHover,
        hoverSidebarOn,
        hoverSidebarOff,
        sidebarToggle,
        toggleSidebar,
        sidebarToggleMobile,
        toggleSidebarMobile,
        sidebarUserbox,
        sidebarShadow,
        sidebarFooter
    } = props;

    const userContext = useUserContext();

    const toggleHoverOn = () => {
        hoverSidebarOn!();
    };

    const toggleHoverOff = () => {
        hoverSidebarOff!();
    }

    const closeDrawer = () => {
        toggleSidebarMobile!();
    };

    const sidebarMenuContent = (
        <div
            className={clsx({
                'app-sidebar-nav-close': sidebarToggle && !sidebarHover
            })}>
            {industryTeamDashboardNavItems.map(list => (
                <SidebarMenu
                    component="div"
                    key={list.label}
                    pages={list.content}
                    title={list.label}
                    collapsed={sidebarToggle}
                />
            ))}
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    open={sidebarToggleMobile}
                    onClose={closeDrawer}
                    variant="temporary"
                    elevation={4}
                    className="app-sidebar-wrapper-lg">
                    <SidebarHeader sidebarToggle={sidebarToggle} toggleSidebar={toggleSidebar} sidebarToggleMobile={sidebarToggleMobile} toggleSidebarMobile={toggleSidebarMobile} />
                    <PerfectScrollbar>
                        {sidebarUserbox && <SidebarUserbox />}
                        {sidebarMenuContent}
                        {sidebarFooter && <SidebarFooter />}
                    </PerfectScrollbar>
                </Drawer>
            </Hidden>

            <Hidden mdDown>
                <Paper
                    className={clsx('app-sidebar-wrapper', {
                        'app-sidebar-wrapper-close': sidebarToggle,
                        'app-sidebar-wrapper-open': sidebarHover,
                        'app-sidebar-wrapper-fixed': sidebarFixed
                    })}
                    square
                    elevation={sidebarShadow ? 11 : 3}>
                    <SidebarHeader sidebarToggle={sidebarToggle} toggleSidebar={toggleSidebar} sidebarToggleMobile={sidebarToggleMobile} toggleSidebarMobile={toggleSidebarMobile} />
                    <div
                        className={clsx({
                            'app-sidebar-menu': sidebarFixed,
                            'app-sidebar-collapsed': sidebarToggle && !sidebarHover
                        })}>
                        <PerfectScrollbar options={{ wheelPropagation: false }}>
                            {sidebarUserbox && <SidebarUserbox />}
                            {sidebarMenuContent}
                            {sidebarFooter && <SidebarFooter />}
                        </PerfectScrollbar>
                    </div>
                </Paper>
            </Hidden>
        </Fragment>
    );
};

export default Sidebar;