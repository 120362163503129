import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Paper, List, ListItem, ListItemText } from '@material-ui/core';

const Footer = (props: any) => {
    const { footerShadow, sidebarToggle, footerFixed } = props;
    return (
        <Fragment>
            <Paper
                square
                elevation={footerShadow ? 11 : 2}
                className={clsx('app-footer text-black-50', {
                    'app-footer--fixed': footerFixed,
                    'app-footer--fixed__collapsed': sidebarToggle
                })}>
                <div className="app-footer--inner">
                    <div>
                        For any issues related to this application please contact: <a href="mailto:ecommercesupport@nucor.com" target="_blank">Contact Us</a>
                    </div>
                    <div className="app-footer--first">
                        © {moment().format('YYYY')} <span>Nucor. All Rights Reserved.</span>
                    </div>
                    <div className="app-footer--second">
                        <a href="Nucor-TermsAndConditionsOfUse.pdf" target="_blank">Terms & Conditions</a>
                        <span className="mx-2">|</span>
                        <a href="Nucor-PrivacyPolicy.pdf" target="_blank">Privacy Policy</a>
                        <span className="mx-2">|</span>
                        <a href="Nucor-CaliforniaPrivacyRights.pdf" target="_blank">California Privacy Policy</a> 
                    </div>
                </div>
            </Paper>
        </Fragment>
    );
};

export default Footer;
