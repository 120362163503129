import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box, Tooltip } from '@material-ui/core';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

export interface ISidebarHeaderProps {
    sidebarToggle?: boolean;
    toggleSidebar?: any;
    sidebarToggleMobile?: boolean;
    toggleSidebarMobile?: any;
    sidebarHover?: boolean;
}

const SidebarHeader = (props: ISidebarHeaderProps) => {
    //const toggleSidebar = () => {
    //    setSidebarToggle(!sidebarToggle);
    //};
    //const toggleSidebarMobile = () => {
    //    setSidebarToggleMobile(!sidebarToggleMobile);
    //};

    const {
        sidebarToggleMobile,
        toggleSidebarMobile,
        sidebarToggle,
        toggleSidebar,
        sidebarHover
    } = props;

    return (
        <Fragment>
            <div
                className={clsx('app-sidebar-header', {
                    'app-sidebar-header-close': sidebarToggle && !sidebarHover
                })}>
                <Box
                    className="header-logo-wrapper"
                    title="Berkshire Partners">
                    <Link to="/" className="header-logo-wrapper-link">
                        <IconButton
                            color="primary"
                            size="medium"
                            className="header-logo-wrapper-btn">
                            {/*<img*/}
                            {/*    className="app-sidebar-logo"*/}
                            {/*    alt="BP ONE"*/}
                            {/*    src={bpOneIconGray}*/}
                            {/*/>*/}
                        </IconButton>
                    </Link>
                    <Box className="header-logo-text">Berkshire Partners</Box>
                </Box>
                <Box
                    className={clsx('app-sidebar-header-btn', {
                        'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover
                    })}>
                    <Tooltip title="Toggle Sidebar" placement="right">
                        <IconButton color="inherit" onClick={toggleSidebar} size="medium">
                            {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box className="app-sidebar-header-btn-mobile">
                    <Tooltip title="Toggle Sidebar" placement="right">
                        <IconButton
                            color="inherit"
                            onClick={toggleSidebarMobile}
                            size="medium">
                            {sidebarToggleMobile ? (<MenuOpenRoundedIcon />) : (<MenuRoundedIcon />)}
                        </IconButton>
                    </Tooltip>
                </Box>
            </div>
        </Fragment>
    );
};

export default SidebarHeader;