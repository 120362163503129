import { createTheme } from '@material-ui/core';
import typography from './typography';

// eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
/* @ts-ignore */
import vars from '!!sass-vars-to-js-loader!../Assets/core/_variables-mui.scss';

const MuiTheme = createTheme({
    palette: {
        primary: {
            main: vars.primary
        },
        grey: {
            300: vars.inheritDefault1,
            A100: vars.inheritDefault2
        },
        secondary: {
            main: vars.secondary
        },
        error: {
            main: vars.red
        },
        success: {
            main: vars.green
        },
        warning: {
            main: vars.orange
        },
        //helpers: {
        //  primary: vars.blue,
        //  main: 'rgba(25, 46, 91, .035)'
        //},
        contrastThreshold: 3,
        tonalOffset: 0.1
    },
    shape: {
        /*borderRadius: 10.0*/
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#fff'
            }
        },
        MuiButton: {
            text: {
                paddingLeft: '14px',
                paddingRight: '14px'
            },
            containedSizeSmall: {
                paddingLeft: '14px',
                paddingRight: '14px'
            },
            root: {
                textTransform: 'none',
                fontWeight: 'normal'
            },
            label: {
                width: 'auto'
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: vars.second,
                padding: '8px 16px',
                fontSize: '13px'
            },
            arrow: {
                color: vars.second
            }
        }
    },
    typography
});

// customization for mui-rte
Object.assign(MuiTheme.overrides, {
    MUIRichTextEditor: {
      container: {

      },
      root: {
        border: "1px solid lightgray",
        minHeight: "8em",
        padding: '0.25em'

      },
      editor: {
        paddingLeft: '0.5em'
      },
      placeHolder: {
        marginLeft: '0.5em',
      }
    }
});
export default MuiTheme;
