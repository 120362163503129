import React from "react";
import { IUser } from '../Models/IUser';

const defaultUserContext: IUser = {};

export const UserContext = React.createContext<{ user: IUser, updateUser: (user: IUser) => any }>({
    user: defaultUserContext,
    updateUser: (user: IUser) => {}
});

export const useUserContext = () => React.useContext(UserContext);
