import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconButton, Box } from '@material-ui/core';
import nucorLogo from '../Assets/images/nucor/nucorLogoWhite.svg';

const HeaderLogo = () => {
    return (
        <Fragment>
            <div className='app-header-logo'>
                <Box className="header-logo-wrapper mr-2" title="Nucor">
                    <Link to="/" className="header-logo-wrapper-link">
                        <IconButton
                            color="primary"
                            size="medium"
                            className="header-logo-wrapper-btn">
                            <img alt="Nucor" src={nucorLogo} style={{ width: '240px' }} />
                        </IconButton>
                    </Link>
                </Box>
            </div>
        </Fragment>
    );
};

export default HeaderLogo;